<template lang="pug">
include ../../../configs/template
div.row.text-left(v-if="statement")
  div.col-xs-12.col-sm-12.col-md-6.col-lg-4
    +data-info('number', 'statement.number')
  div.col-xs-12.col-sm-12.col-md-6.col-lg-4
    +data-info('createDate', 'statement.created_at')
  div.col-xs-12.col-sm-12.col-md-6.col-lg-4
    +data-info('rank', 'getDirectoryObject({ value: "ranks", id:statement.rank})[nameLang]')
  div.col-xs-12.col-sm-12.col-md-6.col-lg-4
    v-btn(color="primary")
      router-link(:to="{ name: nameRoute, params: { documentID: statement.origin_work_document.id } }" target="_blank" class="white--text")
        | {{ $t('openDocumentWasCreateStatement') }}
  div.col-xs-12.col-sm-12.col-md-6.col-lg-4
    div.d-flex
      b.mr-2 {{ $t('position') }}:
      div(v-for="(item, index) in statement.position" :key="`position-${item.id}`")
        span {{ getDirectoryObject({ value: "positions", id:item })[nameLang] }} {{ statement.position.length - 1 !== index ? ';' : null }}
  div.col-xs-12.col-sm-12.col-md-6.col-lg-4
    +data-info('typeDoc', 'getDirectoryObject({ value: "typeDocQualification", id: statement.type_document })[nameLang]')
  div.col-xs-12.col-sm-12.col-md-6.col-lg-4(v-if="Object.keys(statement.replaced_work_document).length" )
    v-btn(color="primary")
      router-link(:to="{ name: nameRoute, params: { documentID: statement.replaced_work_document.id } }" target="_blank" class="white--text")
        | {{ $t('openDocumentCreatedOfStatement') }}
  div.col-xs-12.col-sm-12.col-md-6.col-lg-4
    +data-info-status('status','getDirectoryObject({ value: "statuses", id: statement.status_document })[nameLang]', 'getStatus(statement.status_document)')
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { getStatus } from '@/mixins/main'
export default {
  props: { statement: { type: Object, default: () => ({}) } },
  data () {
    return {
      getStatus,
      typeDocProof: [16]
    }
  },
  computed: {
    ...mapGetters(['getDirectoryObject']),
    ...mapState({
      nameLang: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr'
    }),
    nameRoute () {
      return this.typeDocProof.includes(this.statement.type_document) ? 'qualification-documents-proof-info' : 'qualification-documents-info'
    }
  }
}
</script>>
